import React from 'react';
import { Navigate } from 'react-router';
import DevPage from '../pages/Development.page';
import OrderPage from '../pages/Order.page';
import OrderSignInPage from '../pages/Order.sign.in.page';
import InvoicePage from '../pages/Invoice.page';

export interface IMapItem {
  path: string;
  element: React.ReactNode;
  children?: IMapItem[];
}

export const siteMap: IMapItem[] = [
  { path: `${process.env.REACT_APP_BASE_URL}ordersignin`, element: <OrderSignInPage /> },
  { path: `${process.env.REACT_APP_BASE_URL}order`, element: <OrderPage /> },
  { path: `${process.env.REACT_APP_BASE_URL}order/invoice`, element: <InvoicePage /> },
  { path: `${process.env.REACT_APP_BASE_URL}order/:tab`, element: <OrderPage /> },
  { path: `${process.env.REACT_APP_BASE_URL}dev`, element: <DevPage /> },
  // { path: '*', element: <Navigate to={`${process.env.REACT_APP_BASE_URL}order`} /> },
  // { path: '/ordersignin', element: <OrderSignInPage /> },
  // { path: '/order', element: <OrderPage /> },
  // { path: '/order/invoice', element: <InvoicePage /> },
  // { path: '/order/:tab', element: <OrderPage /> },
  // { path: '/dev', element: <DevPage /> },
  { path: '*', element: <Navigate to={`${process.env.REACT_APP_BASE_URL}order`} /> },
];
