import { ChangeEvent, type Dispatch, type SetStateAction } from "react";

type TInputEvent = ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
type TInputEventHandle = (e: TInputEvent | boolean) => unknown;

// --
// -- Generate hendler for for meild using setState action
// -- Supported field types: String, Boolean
// --
export function useHandleField<T>(setState: Dispatch<SetStateAction<T>>) {
  return (key: keyof T): TInputEventHandle =>
    (e) => setState((prevState) => ({
      ...prevState,
      [key]: typeof e === 'boolean'
        ? e
        : e?.target?.value || '',
    }));      
}