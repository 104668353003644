import { FC } from "react";
import { Helmet } from "react-helmet-async";

const Meta: FC<{
  title?: string,
  meta?: React.ReactNode,
}> = ({
  title,
  meta,
}) => 
    <Helmet>
      {!!title && <title>{`${title} ${process.env.REACT_APP_PROJECT_TITLE ?? ''}`}</title>}
      {!!meta && meta}
    </Helmet>


export default Meta;
