import { ZodError } from "zod";

export type IZodErrorsState<T> = {
  [key in keyof T]?: any;
};

// --
// -- Checking form fields using Zod cscheme
// --
export default function checkFormStateByZod<T>(zodSchama: Record<string, any>, data: T): IZodErrorsState<T> | null { 
  try {
    zodSchama.parse(data);
  } catch (error) {
    if ((error as ZodError)?.errors?.length)
      return (error as ZodError).errors?.length
        ? (error as ZodError).errors.reduce((acc, err) => ({
            ...acc,
            [err?.path?.[0] || 'unknown']: err?.message || 'unknown',
          }), {})
        : {};      
  }

  return null;
}