import React, { ErrorInfo } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { store } from './store';
import { Toaster } from 'react-hot-toast';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const logError = (error: Error, info: ErrorInfo) => {};

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <Toaster position='bottom-right' />
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
        <App />
      </ErrorBoundary>      
    </HelmetProvider>
  </Provider>
);


