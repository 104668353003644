import { icons, iconsLib } from "../icons/IconSet";

export default function DevPage() {
  return (
    <div className="max-w-5xl mx-auto">
      <h1 className="text-4xl text-center pt-8 pb-12">Icon set</h1>
      <div className="grid grid-cols-6 gap-x-2 gap-y-6">
        {Object.keys(iconsLib).map((key, i) => (
          <div className="col-span-1 grid gap-1 justify-center" key={`icon-${i}`}>
            <div className="flex justify-center">{icons(key as keyof typeof iconsLib)}</div>
            <div>{key}</div>
          </div>
        ))}
      </div>
    </div>
  );
}