import { FC } from 'react';
import logoAllusedparts from './allusedparts.png';
import logoAutoenginesoutlet from './autoenginesoutlet.svg';
import logoAutomotix from './automotix.png';
import logoBuyusedengine from './buyusedengine.png';

const logos = {
  'allusedparts': logoAllusedparts,
  'autoenginesoutlet': logoAutoenginesoutlet,
  'buyusedengine': logoBuyusedengine,
  '': logoAutomotix,
};

const Logo: FC<{ size?: 'small' | 'medium' | 'large', className?: string, }> = ({
  size = 'medium',
  className,
}) => (
  <div className={`
    ${className || ''}
    inline-flex relative items-center            
  `}>
    <img 
      src={Object.entries({
          'allusedparts': logoAllusedparts,
          'autoenginesoutlet': logoAutoenginesoutlet,
          'buyusedengine': logoBuyusedengine,
        })
          .find(([domen, Logo]) => window.location.origin.includes(domen))
          ?.[1] || logoAutomotix
      }
      className={{
          'small': 'h-[35px] sm:h-[49px]',
          'medium': 'h-[45px] sm:h-[62px]',
          'large': 'h-[55px] sm:h-[80px]',
        }[size]
      }
    />
  </div>

);


export default Logo;
