import type { PropsWithChildren, MouseEvent, CSSProperties } from "react";
import { TFormSize } from "./TFormSize";

export default function Button ({  
  children,
  onClick,
  size = 'medium',
  variant = 'filled',
  type = 'button',
  disabled = false,
  className,
  style,
  isNotFullWidth = false,
}: PropsWithChildren<{
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void,
  size?: TFormSize,
  variant?: 'outline' | 'filled',
  type?: 'button' | 'reset' | 'submit',
  disabled?: boolean,
  className?: string,
  style?: CSSProperties,
  isNotFullWidth?: boolean,
}>) {
  return (
    <button
      type={type}
      {...(onClick ? { onClick } : {})}
      {...(style ? { style } : {})}
      disabled={disabled}
      className={`
        border-[1px] font-bold transition duration-300 py-2 px-4 rounded-xl disabled:opacity-50 tracking-wide
        ${!!!isNotFullWidth && 'w-full'} 
        ${{
          'tiny':   'text-xs py-[3px] px-[7px]',
          'small':  'text-xs py-1 px-2',
          'medium': '',
          'large':  'py-4 px-4',
        }?.[size]} 
        ${{
          'outline': `bg-gradient-to-b from-[#ebebeb] to-[#e1e1e1] text-black tracking-wide shadow-sm ${!disabled ? 'hover:from-[#e1e1e1] hover:to-[#ebebeb] hover:shadow-md' : ''} `,
          'filled': `bg-gradient-to-b from-[#80B742] to-[#5C8D3B] text-white shadow-sm ${!disabled ? 'hover:from-[#5C8D3B] hover:to-[#80B742] hover:shadow-lg' : ''}`,
        }?.[variant]}
        ${className ? className : ''}
      `}
      style={{
        // textShadow: {
        //   'outline': `rgba(0, 0, 0, .2) 1px 1px 1px`,
        //   'filled': `rgba(255, 255, 255, .2) 1px 1px 1px`,
        // }?.[variant] || '',
      }}
    >
      {children}
    </button>
  );
}